import "./About.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function About() {
    const techStack = [
        "Python", "Django", "Django Rest Framework", "HTML", "CSS", "SCSS", "Javascript", "Typescript", "React",
        "Postgres", "MongoDB", "Redis", "RabbitMQ", "Docker", "Kubernetes", "Linode", "AWS", "Cloudflare",
        "OpenAI", "Langchain"
    ]

    return (
        <div className={"about-container"}>
            {/* --------------- TECH STACK --------------- */}
            <section>
                <h3 className={"is-size-3 secondary-font has-text-centered"}>
                    My <span className={"has-text-primary"}>Tech Stack</span>
                </h3>
                <p className={"mb-5 has-text-centered"}>
                    These are the languages, frameworks, libraries, services and tools <br/>I have used and
                    worked with till now.
                </p>
                <div className={"all-tech-stack-container"}>
                    {techStack.map(tech => (
                        <span className="tag is-medium m-1" key={tech}>{tech}</span>
                    ))}
                </div>
            </section>
            <hr className={"mt-6 mb-6 dropdown-divider w-100"}/>
            {/* --------------- WORK EXP TIMELINE --------------- */}
            <section>
                <h3 className={"is-size-3 secondary-font has-text-centered mb-5"}>
                    <span className={"has-text-primary"}>Work Experience</span> Timeline
                </h3>
                <div className="timeline">
                    <div className="timeline-item is-primary">
                        <div className="timeline-marker is-icon is-info">
                            <FontAwesomeIcon icon={["fas", "briefcase"]}/>
                        </div>
                        <div className="timeline-content">
                            <p className="heading">April 2023</p>
                            <p>Started Work at <b>Abun</b><br/> as Full-Stack Developer</p>
                        </div>
                    </div>
                    <div className="timeline-item is-primary">
                        <div className="timeline-marker is-icon is-info">
                            <FontAwesomeIcon icon={["fas", "briefcase"]}/>
                        </div>
                        <div className="timeline-content">
                            <p className="heading">June 2022</p>
                            <p>Started Work at <b>Prospectss</b><br/> as Full-Stack Developer</p>
                        </div>
                    </div>
                    <div className="timeline-item is-primary">
                        <div className="timeline-marker is-icon is-info">
                            <FontAwesomeIcon icon={["fas", "briefcase"]}/>
                        </div>
                        <div className="timeline-content">
                            <p className="heading">April 2019</p>
                            <p>Started Work at <b>Draftss</b><br/> as Full-Stack Developer</p>
                        </div>
                    </div>
                    <div className="timeline-header">
                        <span className="tag is-medium is-primary has-text-dark">
                            Start
                        </span>
                    </div>
                </div>
            </section>
        </div>
    )
}