import "./Card.scss";
import {ReactNode} from "react";

interface CardProps {
    children: ReactNode
    className?: string
}

export default function Card(props: CardProps) {
    return (
        <div className={`card ${props.className ? props.className : ""}`}>
            <div className={"card-content"}>
                <div className={"content"}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}