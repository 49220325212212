import {RouteObject} from "react-router-dom";
import Home from "./Home/Home";
import Base from "../layouts/Base";
import Projects from "./Projects/Projects";
import About from "./About/About";

export const pageURL = {
    home: "/",
    about: "/about",
    projects: "/projects"
}

const routes: RouteObject[] = [
    {
        element: <Base/>,
        children: [
            {
                element: <Home/>,
                path: pageURL['home'],
            },
            {
                element: <About/>,
                path: pageURL['about'],
            },
            {
                element: <Projects/>,
                path: pageURL['projects'],
            }
        ]
    }
]

export default routes;
