import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import routes from "./pages/routes";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faLinkedin,
    faGithub,
    faGitlab,
} from "@fortawesome/free-brands-svg-icons"
import {
    faBriefcase
} from '@fortawesome/free-solid-svg-icons'

library.add(faLinkedin, faGithub, faGitlab, faBriefcase);

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <RouterProvider router={router}/>
  </React.StrictMode>
);
