import "./Navbar.scss";
import {Link, NavLink} from "react-router-dom";
import {pageURL} from "../../pages/routes";
import {useState} from "react";

import logo from "../../assets/images/branding/arunnairdev_logo.png";


export default function Navbar() {
    const [hamburgerActive, setHamburgerActive] = useState(false);
    const [navbarMobileActive, setNavbarMobileActive] = useState(false);

    function toggleMobileNavbarMenu() {
        setHamburgerActive(!hamburgerActive);
        setNavbarMobileActive(!navbarMobileActive);
    }

    return (
        <nav className="navbar pt-4 pl-4 pr-4"
             role="navigation"
             aria-label="main navigation">
            <div className="navbar-brand">
                <Link to={pageURL['home']}>
                    <img src={logo} width="48" height="auto" alt={"logo"}/>
                </Link>

                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <button className={`navbar-burger ${hamburgerActive ? "is-active" : ""}`}
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navbarmenu" onClick={toggleMobileNavbarMenu}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div className={`navbar-menu ${navbarMobileActive ? "is-active" : ""}`}
                 id={"navbarmenu"}>
            <div className={"navbar-end"}>
                    <NavLink to={pageURL['home']} className={"navbar-item"}>Home</NavLink>
                    <NavLink to={pageURL['about']} className={"navbar-item"}>About</NavLink>
                    <NavLink to={pageURL['projects']} className={"navbar-item"}>Projects</NavLink>
                </div>
            </div>
        </nav>
    )
}