import "./LinkButton.scss";
import {Link, To} from "react-router-dom";
import {ReactNode} from "react";

interface LinkButtonProps {
    children: ReactNode
    to: To
    theme: "primary" | "success" | "warning" | "danger" | "info"
    className?: string
}

export default function LinkButton(props: LinkButtonProps) {
    return (
        <Link to={props.to} className={`button is-${props.theme} ${props.className ? props.className : ""}`}>
            {props.children}
        </Link>
    )
}