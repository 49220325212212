import {Outlet} from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";

export default function Base() {
    return (
        <div className={"is-flex is-flex-direction-column"}>
            <Navbar/>
            <div className={"container mt-4 mb-6"}>
                <Outlet/>
            </div>
        </div>
    )
}